var render = function () {
  var _vm$detailProgram, _vm$detailProgram$pro, _vm$detailProgram2, _vm$detailProgram2$pr, _vm$detailProgram3, _vm$detailProgram3$pr, _vm$detailProgram4, _vm$detailProgram5, _vm$detailProgram6, _vm$detailProgram7, _vm$detailProgram8;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "max-width": "1270px",
      "mx": "auto",
      "my": ['0', '16px'],
      "bg": ['#fff', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['0.5rem', '1.5rem'],
      "min-height": ['auto', '74vh']
    }
  }, [_c('Stepper', {
    attrs: {
      "margin-top": "16px",
      "margin-bottom": ['12px', '20px'],
      "steps": [{
        label: 'Informasi Pribadi',
        isCurrentStep: true
      }, {
        label: 'Periksa'
      }, {
        label: 'Pembayaran'
      }]
    }
  }), _c('c-box', {
    attrs: {
      "border": ['none', '1px solid #C4C4C4'],
      "border-radius": ['none', '12px'],
      "padding": ['0 22px', '30px'],
      "max-width": ['auto', '900px'],
      "margin": "auto"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "28px",
      "text-align": "center"
    }
  }, [_vm._v(" Informasi Pribadi ")]), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "mt": ['12px', '40px']
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "outline",
      "border-radius": "1000px",
      "font-size": ['14px', '18px'],
      "disabled": _vm.dataMixinsLogin.isLoadingLoginWithGoogle,
      "font-weight": "500",
      "padding": ['0 30px', '0 40px'],
      "height": ['40px', '60px'],
      "color": "#1A202C",
      "_hover": {
        backgroundColor: '#008C81',
        color: 'white'
      },
      "_active": {
        backgroundColor: '#005A64',
        color: 'white'
      }
    },
    on: {
      "click": _vm.handleGoogle_
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/ic-google.svg'),
      "height": "20px",
      "min-height": "20px",
      "width": "20px",
      "min-width": "20px"
    }
  }), _c('c-box', {
    attrs: {
      "as": "span",
      "padding-left": "8px"
    }
  }, [_vm._v(" Daftar dengan Google ")])], 1)], 1), _c('c-text', {
    attrs: {
      "mt": ['12px', '16px'],
      "text-align": "center",
      "font-size": ['12px', '16px'],
      "color": "neutral.darkGray"
    }
  }, [_vm._v(" atau ")]), _c('c-flex', {
    attrs: {
      "gap": ['7px', '10px'],
      "mt": ['12px', '16px'],
      "mb": ['22px', '40px'],
      "justify": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px",
      "text-align": ['center', 'start']
    }
  }, [_vm._v(" Sudah punya akun? ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px",
      "color": "primary.400",
      "as": "a"
    },
    on: {
      "click": function click($event) {
        return _vm.handleIsOpenModalLogin(true);
      }
    }
  }, [_vm._v(" Login ")])], 1), _c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "20px"
    }
  }, [_vm._v(" Detail Program ")]), _c('BaseDivider'), _c('c-flex', {
    attrs: {
      "gap": ['10px', '30px'],
      "align-items": "center",
      "margin": ['10px 0', '20px 0']
    }
  }, [(_vm$detailProgram = _vm.detailProgram) !== null && _vm$detailProgram !== void 0 && (_vm$detailProgram$pro = _vm$detailProgram.product) !== null && _vm$detailProgram$pro !== void 0 && _vm$detailProgram$pro.photoUrl ? _c('c-image', {
    attrs: {
      "border-radius": "12px",
      "width": ['70px', '120px'],
      "height": ['89px', '153px'],
      "object-fit": "cover",
      "src": (_vm$detailProgram2 = _vm.detailProgram) === null || _vm$detailProgram2 === void 0 ? void 0 : (_vm$detailProgram2$pr = _vm$detailProgram2.product) === null || _vm$detailProgram2$pr === void 0 ? void 0 : _vm$detailProgram2$pr.photoUrl,
      "alt": "Product Image"
    }
  }) : _vm._e(), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" " + _vm._s((_vm$detailProgram3 = _vm.detailProgram) === null || _vm$detailProgram3 === void 0 ? void 0 : (_vm$detailProgram3$pr = _vm$detailProgram3.product) === null || _vm$detailProgram3$pr === void 0 ? void 0 : _vm$detailProgram3$pr.name) + " "), _c('c-text', {
    attrs: {
      "display": ['inline', 'none']
    }
  }, [_vm._v(" " + _vm._s((_vm$detailProgram4 = _vm.detailProgram) === null || _vm$detailProgram4 === void 0 ? void 0 : _vm$detailProgram4.programsService) + " (" + _vm._s((_vm$detailProgram5 = _vm.detailProgram) === null || _vm$detailProgram5 === void 0 ? void 0 : _vm$detailProgram5.duration) + " Hari) ")])], 1), _c('BaseText', {
    attrs: {
      "display": ['none', 'inline'],
      "size-mobile": "16px",
      "size-desktop": "16px"
    }
  }, [_vm._v(" " + _vm._s((_vm$detailProgram6 = _vm.detailProgram) === null || _vm$detailProgram6 === void 0 ? void 0 : _vm$detailProgram6.programsService) + " (" + _vm._s((_vm$detailProgram7 = _vm.detailProgram) === null || _vm$detailProgram7 === void 0 ? void 0 : _vm$detailProgram7.duration) + " Hari) ")])], 1), _c('router-link', {
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center",
      "margin-top": "8px"
    },
    attrs: {
      "to": _vm.getHandleMore
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Selengkapnya ")]), _c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-arrow-right.svg'),
      "height": "12",
      "width": "12",
      "fill": "#333333"
    }
  })], 1), _c('BaseText', {
    attrs: {
      "margin-top": "8px",
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(((_vm$detailProgram8 = _vm.detailProgram) === null || _vm$detailProgram8 === void 0 ? void 0 : _vm$detailProgram8.price) || 0)) + " ")])], 1)], 1), _c('BaseDivider', {
    attrs: {
      "margin-bottom": "16px"
    }
  }), _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('c-flex', {
    attrs: {
      "direction": ['column', 'row'],
      "gap": ['0', '20px']
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "text",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.firstName),
      "label": "Nama Depan",
      "placeholder": "Masukkan nama depan",
      "icon-left-element": require('@/assets/ic-profile.svg'),
      "full-width": "",
      "invalid-text": _vm.parseErrors('Nama depan', _vm.$v.firstName)
    },
    on: {
      "blur": _vm.$v.firstName.$touch
    },
    model: {
      value: _vm.firstName,
      callback: function callback($$v) {
        _vm.firstName = $$v;
      },
      expression: "firstName"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "text",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.lastName),
      "label": "Nama Belakang",
      "placeholder": "Masukkan nama belakang",
      "icon-left-element": require('@/assets/ic-profile.svg'),
      "full-width": "",
      "invalid-text": _vm.parseErrors('Nama belakang', _vm.$v.lastName)
    },
    on: {
      "blur": _vm.$v.lastName.$touch
    },
    model: {
      value: _vm.lastName,
      callback: function callback($$v) {
        _vm.lastName = $$v;
      },
      expression: "lastName"
    }
  })], 1), _c('FormInput', {
    attrs: {
      "type": "text",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.phoneNumber),
      "label": "Nomor Telepon",
      "placeholder": "Masukkan nomor telepon",
      "icon-left-element": require('@/assets/ic-phone.svg'),
      "icon-left-element-color": "#888888",
      "invalid-text": _vm.parseErrors('Nomor telepon', _vm.$v.phoneNumber)
    },
    on: {
      "blur": _vm.$v.phoneNumber.$touch
    },
    model: {
      value: _vm.phoneNumber,
      callback: function callback($$v) {
        _vm.phoneNumber = $$v;
      },
      expression: "phoneNumber"
    }
  }), _c('c-flex', {
    attrs: {
      "align-items": "start",
      "gap": ['4px', '20px'],
      "mb": "24px"
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "text",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.email),
      "label": "Email",
      "placeholder": "Masukkan email",
      "icon-left-element": require('@/assets/ic-mail.svg'),
      "icon-left-element-color": "#888888",
      "is-without-margin-bottom": "",
      "full-width": "",
      "invalid-text": _vm.parseErrors('Email', _vm.$v.email),
      "is-disabled": _vm.dataMixinsModalOtp.isValidOtp === true
    },
    on: {
      "blur": _vm.$v.email.$touch,
      "trigger-change": _vm.resetCountDown
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('BaseButton', {
    attrs: {
      "size": "large",
      "variant": "contained",
      "h": ['48px', '62px'],
      "min-width": ['73px', '200px'],
      "margin-top": ['26px', '28px'],
      "disabled": _vm.$v.email.$invalid || _vm.dataMixinsModalOtp.isValidOtp === true,
      "is-loading": _vm.isLoadingEmailOtpSend
    },
    on: {
      "click": function click($event) {
        return _vm.handleIsOpenModalOtp(true);
      }
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px",
      "size-desktop": "14px",
      "as": "span"
    }
  }, [_vm._v(" Verifikasi ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "direction": ['column', 'row'],
      "gap": ['0', '20px']
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "password",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.password),
      "label": "Kata Sandi",
      "placeholder": "Masukkan kata sandi",
      "icon-left-element": require('@/assets/ic-padlock.svg'),
      "full-width": "",
      "invalid-text": _vm.parseErrors('Kata sandi', _vm.$v.password)
    },
    on: {
      "blur": _vm.$v.password.$touch
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "password",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.confirmPassword),
      "label": "Konfirmasi Kata Sandi",
      "placeholder": "Masukkan konfirmasi kata sandi",
      "icon-left-element": require('@/assets/ic-padlock.svg'),
      "full-width": "",
      "invalid-text": _vm.parseErrors('Konfirmasi kata sandi', _vm.$v.confirmPassword)
    },
    on: {
      "blur": _vm.$v.confirmPassword.$touch
    },
    model: {
      value: _vm.confirmPassword,
      callback: function callback($$v) {
        _vm.confirmPassword = $$v;
      },
      expression: "confirmPassword"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "gap": ['4px', '16px']
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "variant": "outlined",
      "w": "100%",
      "border-radius": "1000px"
    },
    on: {
      "click": _vm.handlePrevious
    }
  }, [_vm._v(" Kembali ")]), _c('BaseButton', {
    attrs: {
      "size": "large",
      "variant": "contained",
      "w": "100%",
      "border-radius": "1000px",
      "disabled": _vm.$v.$invalid
    },
    on: {
      "click": _vm.handleNext
    }
  }, [_vm._v(" Selanjutnya ")])], 1)], 1), _c('ModalLogin', {
    attrs: {
      "is-open": _vm.isOpenModalLogin
    },
    on: {
      "handle-is-open": _vm.handleIsOpenModalLogin
    }
  }), _c('ModalOtp', {
    attrs: {
      "is-open": _vm.isOpenModalEmailOtp
    },
    on: {
      "handle-is-open": _vm.setIsOpenModalEmailOtp,
      "trigger-result-verify": function triggerResultVerify($event) {
        _vm.isVerifiedEmail = $event;
      }
    }
  }), _c('ModalInfo', {
    attrs: {
      "for": "errorEmailAlreadyRegistered",
      "with-button-close": false,
      "is-open": _vm.isOpenModalErrorEmailAlreadyRegistered
    },
    on: {
      "close": function close($event) {
        _vm.isOpenModalErrorEmailAlreadyRegistered = false;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }