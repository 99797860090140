import { mapGetters, mapMutations, mapActions } from 'vuex'
// import { DUMMY_SECONDS } from '@/utils/dummy-data'

export default {
  data() {
    return {
      isLoadingEmailOtpSend: false,
      isLoadingEmailOtpVerify: false,
    }
  },
  computed: {
    ...mapGetters({
      dataMixinsModalOtp: 'general/mixinsModalOtp',
    }),
    isOpenModalEmailOtp() {
      return this.dataMixinsModalOtp.isOpenModalEmailOtp
    },
    isDisabledEmailOtpSend() {
      return this.isOpenModalEmailOtp || this.dataMixinsModalOtp.countDownStatus === 'running' || this.dataMixinsModalOtp.isValidOtp
    },
  },
  methods: {
    ...mapMutations({
      setMixinsModalOtp: 'general/setMixinsModalOtp',
    }),
    ...mapActions({
      emailOtpSend: 'auth/emailOtpSend',
      emailOtpVerify: 'auth/emailOtpVerify',
    }),
    setIsOpenModalEmailOtp(data) {
      this.setMixinsModalOtp({ key: 'isOpenModalEmailOtp', data })
    },
    setEmail(data) {
      this.setMixinsModalOtp({ key: 'email', data })
    },
    setOtp(data) {
      this.setMixinsModalOtp({ key: 'otp', data })
    },
    setIsValidOtp(data) {
      this.setMixinsModalOtp({ key: 'isValidOtp', data })
    },
    setCountDownStatus(data) {
      this.setMixinsModalOtp({ key: 'countDownStatus', data })
    },
    setCountDown(data) {
      this.setMixinsModalOtp({ key: 'countDown', data })
    },
    setInterval_(data) {
      this.setMixinsModalOtp({ key: 'interval', data })
    },
    async handleEmailOtpSend({ email }) {
      try {
        if (this.dataMixinsModalOtp.countDownStatus === 'running') {
          this.setIsOpenModalEmailOtp(true)
          return
        }
        this.isLoadingEmailOtpSend = true
        this.setEmail(email)
        const res = await this.emailOtpSend({ email })
        if (res.data?.isRegistered) {
          throw new Error('Email is already registered!')
        }
        this.setIsOpenModalEmailOtp(true)
      } finally {
        this.isLoadingEmailOtpSend = false
      }
    },
    async handleEmailOtpResend({ email }) {
      try {
        if (this.dataMixinsModalOtp.countDownStatus === 'running') {
          this.setIsOpenModalEmailOtp(true)
          return
        }
        this.startCountDown()
        this.isLoadingEmailOtpSend = true
        this.setEmail(email)
        const res = await this.emailOtpSend({ email })
        if (res.data?.isRegistered) {
          throw new Error('Email is already registered!')
        }
        this.setIsOpenModalEmailOtp(true)
        this.$toast({
          title: 'Success',
          description: 'Resend OTP successfully!',
          status: 'success',
          duration: 5000,
          position: 'bottom-right',
          variant: 'subtle',
        })
      } finally {
        this.isLoadingEmailOtpSend = false
      }
    },
    async handleEmailOtpVerify({ email, otp }, callback = () => {}) {
      try {
        this.isLoadingEmailOtpVerify = true
        this.setEmail(email)
        this.setOtp(otp)
        await this.emailOtpVerify({ email, otp })
        this.$toast({
          title: 'Success',
          description: 'Email verified successfully!',
          status: 'success',
          duration: 5000,
          position: 'bottom-right',
          variant: 'subtle',
        })
        this.setIsOpenModalEmailOtp(false)
        this.setIsValidOtp(true)
        callback(true)
      } catch (e) {
        this.setIsValidOtp(false)
        callback(false)
      } finally {
        this.isLoadingEmailOtpVerify = false
      }
    },
    resetCountDown() {
      if (this.dataMixinsModalOtp.interval === null) return
      clearInterval(this.dataMixinsModalOtp.interval)
      this.setCountDownStatus('idle')
      this.setCountDown(30) // DUMMY_SECONDS
    },
    startCountDown() {
      if (this.dataMixinsModalOtp.countDownStatus === 'running') return
      this.setCountDownStatus('running')
      this.setInterval_(setInterval(() => {
        this.dataMixinsModalOtp.countDown -= 1
        if (this.dataMixinsModalOtp.countDown <= 0) {
          clearInterval(this.dataMixinsModalOtp.interval)
          this.setCountDownStatus('idle')
          this.setCountDown(30) // DUMMY_SECONDS
        }
      }, 1000))
    },
  },
  watch: {
    isOpenModalEmailOtp(val) {
      if (val === true) {
        this.setOtp('')
        this.setIsValidOtp(null)
      }
    },
  },
}