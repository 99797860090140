<template>
  <c-modal
    :is-open="isOpen"
    :on-close="close"
    :size="['sm', 'md'].includes(currentBreakpoint) ? 'md' : 'xl'"
    :initial-focus-ref="() => {}"
    :final-focus-ref="() => {}"
  >
    <c-modal-content
      ref="content"
      :border-radius="['8px', '16px']"
    >
      <c-modal-body
        width="100%"
        max-width="430px"
        margin="auto"
        padding="30px 30px 0 30px"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
          color="primary.400"
          text-align="center"
          :margin-bottom="['20px','30px']"
        >
          Login
        </BaseText>

        <FormInput
          v-model="email"
          type="text"
          is-required
          :is-invalid="isInvalidField($v.email)"
          label="Email"
          placeholder="Masukkan email"
          :icon-left-element="require('@/assets/ic-mail.svg')"
          icon-left-element-color="#888888"
          full-width
          @blur="$v.email.$touch"
        />

        <FormInput
          v-model="password"
          type="password"
          is-required
          :is-invalid="isInvalidField($v.password)"
          label="Kata Sandi"
          placeholder="Masukkan kata sandi"
          :icon-left-element="require('@/assets/ic-padlock.svg')"
          full-width
          @blur="$v.password.$touch"
        />
      </c-modal-body>
      <c-modal-footer
        d="block"
        padding="0 30px 30px 30px"
        width="100%"
        max-width="420px"
        margin="auto"
      >
        <BaseButton
          size="medium"
          variant="contained"
          w="100%"
          border-radius="1000px"
          :disabled="$v.$invalid"
          :is-loading="mixinsUseRequest_data.handleLogin.isLoading"
          @click="handleLogin"
        >
          Masuk
        </BaseButton>
        <BaseDivider
          text="atau"
          :margin="['22px 0', '20px 0']"
        />
        <BaseButton
          size="medium"
          variant="outlined"
          w="100%"
          border-radius="1000px"
          :left-svg-icon="require('@/assets/ic-google.svg')"
          :is-loading="dataMixinsLogin.isLoadingLoginWithGoogle"
          @click="handleGoogle_"
        >
          Masuk dengan Google
        </BaseButton>
      </c-modal-footer>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import FormInput from '@/views/client/kuisioner/forms/form-input.vue'
import { required } from 'vuelidate/lib/validators'
import BaseButton from '@/components/elements/base-button.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import mixinsLogin from '@/views/buy/_widgets/mixins-login'
import { mapMutations, mapGetters } from 'vuex'
import mixinsUseRequest from '@/mixins/mixins-use-request'
// import { DUMMY_QUERY_PARAMS } from '@/utils/dummy-data'

export default {
  name: 'ModalLogin',
  components: { BaseDivider, BaseButton, FormInput, BaseText },
  mixins: [mixinsCheckBreakpoints(), mixinsLogin, mixinsUseRequest],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  computed: {
    ...mapGetters({
      redirect: 'auth/redirect',
    }),
  },
  mixinsUseRequest_registerKey: [
    'handleLogin',
  ],
  validations: {
    email: { required },
    password: { required },
  },
  methods: {
    ...mapMutations({
      setRedirect: 'auth/setRedirect',
    }),
    isInvalidField,
    close() {
      this.$emit('handle-is-open', false)
    },
    handleLogin() {
      this.mixinsUseRequest_useRequest('handleLogin', async() => {
        const res = await this.mixinsLogin_handleLoginWithEmail({
          email: this.email,
          password: this.password,
        })
        this.$router.push({
          name: 'client.buy-program.step-2',
          query: this.$route.query,
        })
        return res
      })
    },
    handleGoogle_() {
      this.setRedirect({
        name: 'client.buy-program.step-2',
        query: this.$route.query,
      })
      this.handleLoginWithGoogle()
    },
  },
}
</script>