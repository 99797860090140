import { mapGetters, mapMutations, mapActions } from 'vuex'
import { parseErrorCatch } from '@/utils/parse-error-catch'

export default {
  computed: {
    ...mapGetters({
      dataMixinsLogin: 'general/mixinsLogin',
    }),
    mixinsLogin_isLoadingLoginWithEmailOrGoogle() {
      return this.dataMixinsLogin.isLoadingLoginWithGoogle || this.dataMixinsLogin.isLoadingLoginWithEmail
    },
  },
  methods: {
    ...mapActions({
      generateGoogleUrl: 'auth/generateGoogleUrl',
      signin: 'auth/signin2',
    }),
    ...mapMutations({
      setUser: 'auth/setUser',
      setToken: 'auth/setToken',
      setRefreshToken: 'auth/setRefreshToken',
      setIsGoogle: 'auth/setIsGoogle',
      setMixinsLogin: 'general/setMixinsLogin',
    }),
    mixinsLogin_setIsLoadingLoginWithGoogle(data) {
      this.setMixinsLogin({ key: 'isLoadingLoginWithGoogle', data })
    },
    mixinsLogin_setIsLoadingLoginWithEmail(data) {
      this.setMixinsLogin({ key: 'isLoadingLoginWithEmail', data })
    },
    async mixinsLogin_handleLoginWithEmail({ email, password }) {
      if (this.mixinsLogin_isLoadingLoginWithEmailOrGoogle) return

      try {
        this.mixinsLogin_setIsLoadingLoginWithEmail(true)
        const res = await this.signin({ email, password })
        let { token, refreshToken, ...user } = res?.data || {}
        this.setUser(user)
        this.setToken(token)
        this.setRefreshToken(refreshToken)
        this.setIsGoogle(user.isGoogle)

        this.$toast({
          status: 'success',
          title: 'Success',
          description: 'login sukses',
          duration: 3000,
        })

        // if (redirect.value) {
        //   router.push(redirect.value)
        // } else {
        //   router.push('/')
        // }

      } catch (e) {
        this.$toast ({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
        throw e
      } finally {
        this.mixinsLogin_setIsLoadingLoginWithEmail(false)
      }
    },
    async handleLoginWithGoogle() {
      if (this.mixinsLogin_isLoadingLoginWithEmailOrGoogle) return

      try {
        this.mixinsLogin_setIsLoadingLoginWithGoogle(true)
        const res = await this.generateGoogleUrl()
        // if (redirect.value) {
        //   setRedirect(redirect.value)
        // }
        const url = res.data
        window.open(url, '_self')
      } catch (e) {
        this.$toast({
          message: e.message,
          type: 'error',
        })
      } finally {
        this.mixinsLogin_setIsLoadingLoginWithGoogle(false)
      }
    },
  },
}